import axios from "axios";
import errorHandler from "./Repository";

const Category = "/dashboard/qa";
const Question = "/dashboard/qa/questions";
const History = "/dashboard/qa/history";

export default {
  getQACategories(categoryInfo) {
    return axios
      .get(`${Category}?service=` + categoryInfo.service)
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },
  createQACategory(categoryInfo) {
    return axios
      .post(`${Category}`, categoryInfo)
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },
  deleteCategory(categoryInfo) {
    return axios
      .delete(`${Category}?id=` + categoryInfo.id)
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },
  getQATestCases(categoryInfo) {
    return axios
      .get(`${Question}?category_id=` + categoryInfo.category_id)
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },
  createTestCase(categoryInfo) {
    return axios
      .post(`${Question}`, categoryInfo)
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },
  deleteTestCase(categoryInfo) {
    return axios
      .delete(`${Question}?id=` + categoryInfo.id)
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },
  getQAHistory(categoryInfo) {
    return axios
      .get(`${History}`)
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },
  createQAHistory(categoryInfo) {
    return axios
      .post(
        `${History}?fail_count=${categoryInfo.fail_count}&pass_count=${categoryInfo.pass_count}`
      )
      .then(({ data }) => {
        return data;
      })
      .catch(errorHandler);
  },
};

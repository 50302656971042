<template>
    <div class="overflow-auto">
    <h1>QA History</h1>

    <b-table
      id="my-table"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
      :striped="striped"
      :bordered="bordered"
      :head-variant="headVariant"
      :table-variant="tableVariant"
    ></b-table>

    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      class="navigation secondary"
      align="fill"
      aria-controls="my-table"
    ></b-pagination>
  </div>
</template>

<script>
import RequestService from '../repositories/QARepository'

export default {
    data() {
        return {
            items: [],
            perPage: 26,
            currentPage: 1,
            striped: true,
            bordered: true,
            headVariant: null,
            tableVariant: '',
            noCollapse: false
        }
    },
    computed: {
      rows() {
        return this.items.length
      }
    },
    methods: {
        async getQAHistory() {
            const history = await RequestService.getQAHistory()
            history.data.forEach((session) => {
                const date = this.formatDate(session.created_at)    
                this.items.push({
                    id: session.id,
                    test_date: date,
                    tests_passed: session.pass_count,
                    tests_failed: session.fail_count,
                })
            })
        },
        formatDate(dateString) {
            const options = { 
                year: "numeric", 
                month: "long", 
                day: "numeric", 
                hour: 'numeric', 
                minute: 'numeric' 
            }
            return new Date(dateString).toLocaleDateString(undefined, options)
        }
    },
    created() {
        this.getQAHistory()
    }
}
</script>

<style scoped>
h1  {
    padding: 1.5rem 0 1.5rem 0;
}

.navigation {
    padding-top: 1.5rem;
}

</style>